import React from 'react';
import {useWpFetch} from '../../lib/hooks/useFetch';
import Link from 'next/link';
import Image from 'next/image';
import {BREAKPOINT, MAX_WIDTH_PX} from 'aac-components/utils/styles';
import IconArrowShort from 'aac-components/components/Icons/Arrow/IconArrowShort';

const AddictionTalkSection = () => {
    const {data} = useWpFetch(
        '/wordpress-api/pages?parent=80296&per_page=3&_fields=id,title,link,slug,acf.episode_cover_image.sizes.large',
    );
    return (
        <section>
            <div className="addiction-talk">
                <div className="header">
                    <div className="header__pre-title">OUR TALK SHOW</div>
                    <h2>Addiction Talk: Recovery Interviews and Stories Talk Show</h2>
                    <p>
                        “Addiction Talk” is an award-winning online talk show featuring
                        the personal stories of celebrities, influencers, and everyday
                        people impacted by addiction.
                    </p>
                </div>
                <div className="episodes">
                    {Array.isArray(data) &&
                        data.map((episode) => {
                            return (
                                <React.Fragment key={episode?.id}>
                                    <Link
                                        href={`/addiction-talk/${episode?.slug}`}
                                        title={episode?.title?.rendered}
                                        style={{
                                            textDecoration: 'none',
                                            color: 'var(--gray-500)',
                                            textAlign: 'left',
                                        }}>
                                        <div className="episode__card">
                                            <div className="episode__card--image">
                                                <Image
                                                    src={
                                                        episode?.acf?.episode_cover_image
                                                            ?.sizes?.large
                                                    }
                                                    fill
                                                    sizes="400px"
                                                    style={{objectFit: 'contain'}}
                                                    alt={episode?.title?.rendered}
                                                />
                                            </div>
                                            <div
                                                className="episode__card--title"
                                                dangerouslySetInnerHTML={{
                                                    __html: episode?.title?.rendered,
                                                }}
                                            />
                                        </div>
                                    </Link>
                                </React.Fragment>
                            );
                        })}
                </div>
                <Link
                    href="/addiction-talk"
                    title="View All Reviews"
                    style={{
                        display: 'flex',
                        margin: '0 auto',
                        fontSize: '18px',
                        fontWeight: 'bold',
                        textDecoration: 'none',
                        justifyContent: 'center',
                        maxWidth: 'fit-content',
                    }}>
                    <span>View All Episodes</span>
                    <span>
                        <IconArrowShort fill="var(--interactive)" rotate="-90" />
                    </span>
                </Link>
            </div>
            <style jsx>
                {`
                    section {
                        background: var(--tertiary-100);
                    }
                    .addiction-talk {
                        max-width: ${MAX_WIDTH_PX};
                        margin: 0 auto;
                        padding: 64px 15px;
                    }
                    @media screen and (min-width: ${BREAKPOINT}) {
                        .addiction-talk {
                            padding: 64px 0;
                            text-align: center;
                        }
                    }
                    .header {
                        max-width: 880px;
                        margin: 0 auto 40px auto;
                    }
                    .header__pre-title {
                        text-align: center;
                        color: var(--secondary-300);
                        font-size: 12px;
                        line-height: 16px;
                        font-weight: 700;
                    }
                    .episodes {
                        display: grid;
                        grid-template-columns: 1fr;
                        grid-gap: 30px;
                        margin-bottom: 52px;
                    }
                    @media screen and (min-width: ${BREAKPOINT}) {
                        .episodes {
                            grid-template-columns: repeat(3, 1fr);
                        }
                    }
                    a {
                        color: var(--gray-500);
                        text-decoration: none;
                        text-align: left;
                    }
                    .episode__card--image {
                        position: relative;
                        width: 100%;
                        height: 200px;
                        margin-bottom: 8px;
                        border-radius: 4px;
                        overflow: hidden;
                        background: #000;
                    }
                `}
            </style>
        </section>
    );
};
export default AddictionTalkSection;
