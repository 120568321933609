import IconArrowShort from 'aac-components/components/Icons/Arrow/IconArrowShort';
import IconBook from 'aac-components/components/Icons/Misc/IconBook';
import IconMedicalReview from 'aac-components/components/Icons/Misc/IconMedicalReview';
import IconGroup from 'aac-components/components/Icons/User/IconGroup';
import {BREAKPOINT, MAX_WIDTH_PX} from 'aac-components/utils/styles';
import Link from 'next/link';
import MedicallyReviewed from '../MedicallyReviewed';

const EditorialPolicySection = () => {
    return (
        <section className="editorial-policy-section">
            <div className="editorial-policy-section__inner">
                <div className="editorial-policy-section__pre-title">
                    OUR CONTENT POLICY
                </div>
                <h2>Editorial Policy & Content Guidelines</h2>
                <div className="copy__main">
                    We are committed to delivering original, truthful, accurate, unbiased,
                    up-to-date information. We strive to create content that is clear,
                    concise, and easy to understand.{' '}
                    <Link
                        href="/about-us/editorial-policy"
                        title="Editorial Policy"
                        style={{textDecoration: 'none'}}>
                        Learn more about our policy
                    </Link>
                    .
                </div>
                <div className="card__container">
                    <div className="card">
                        <div className="card__icon">
                            <IconBook fill="#fff" width="35" height="35" />
                        </div>
                        <div className="card__title">Our Policy</div>
                        <div className="card__copy">
                            AAC does not accept any advertisements or sponsorships. Our
                            pages remain true to current best practices, without the
                            influence of sponsorship or advertising.
                        </div>
                    </div>
                    <div className="card">
                        <div className="card__icon">
                            <IconMedicalReview fill="#fff" width="35" height="35" />
                        </div>
                        <div className="card__title">Our Sources</div>
                        <div className="card__copy">
                            During the editorial and medical review process, our team fact
                            checks information and ensures that we only use reputable,
                            credible, and current sources in our content.
                        </div>
                    </div>

                    <div className="card">
                        <div className="card__icon">
                            <IconGroup fill="#fff" width="35" height="35" />
                        </div>
                        <div className="card__title">Our Contributors</div>
                        <div className="card__copy">
                            Our editors and medical reviewers include AAC staff and
                            industry experts. Our credentialed editors are addiction
                            experts who review content to ensure that it is accurate and
                            current.
                        </div>
                    </div>
                </div>
            </div>
            <style jsx>
                {`
                    section {
                        background: var(--tertiary-100);
                        padding: 40px 15px;
                    }
                    @media screen and (min-width: ${BREAKPOINT}) {
                        section {
                            padding: 80px 0 40px;
                        }
                    }
                    .editorial-policy-section__inner {
                        max-width: ${MAX_WIDTH_PX};
                        margin: 0 auto;
                    }
                    .editorial-policy-section__pre-title {
                        text-align: center;
                        color: var(--secondary-300);
                        font-size: 12px;
                        line-height: 16px;
                        font-weight: 700;
                    }
                    h2 {
                        text-align: center;
                        margin: 14px 0 24px;
                    }
                    a {
                        font-weight: bold;
                        text-decoration: none;
                        display: flex;
                        align-items: center;
                        margin: 0 auto 40px auto;
                        text-align: center;
                        justify-content: center;
                        grid-gap: 8px;
                    }
                    .card {
                        text-align: center;
                        max-width: 345px;
                        margin: 0 auto;
                        position: relative;
                    }
                    .card__container {
                        display: grid;
                        grid-template-columns: 1fr;
                        grid-gap: 0;
                    }
                    @media screen and (min-width: ${BREAKPOINT}) {
                        .card__container {
                            grid-template-columns: repeat(3, 1fr);
                            margin-bottom: 52px;
                            grid-gap: 80px;
                        }
                    }
                    .copy__main {
                        font-size: 16px;
                        line-height: 24px;
                        text-align: center;
                        margin-bottom: 40px;
                    }
                    @media screen and (min-width: ${BREAKPOINT}) {
                        .copy__main {
                            max-width: 870px;
                            margin: 0 auto 40px auto;
                        }
                    }
                    .card::after {
                        content: '';
                        width: 80px;
                        height: 4px;
                        display: block;
                        margin: 0 auto 24px auto;
                        border-radius: 2em;
                        background: var(--secondary-100);
                    }
                    @media screen and (min-width: ${BREAKPOINT}) {
                        .card::after {
                            height: 80px;
                            width: 4px;
                            position: absolute;
                            right: -40px;
                            top: 30%;
                            bottom: 0;
                        }
                    }
                    .card:last-child::after {
                        display: none;
                    }
                    .card__icon {
                        background: var(--tertiary);
                        width: 60px;
                        height: 60px;
                        border-radius: 16px;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        margin: 0 auto 16px auto;
                    }
                    .card__title {
                        margin: 0 auto 8px auto;
                        color: var(--primary-400);
                        font-size: 18px;
                        line-height: 28px;
                        font-weight: bold;
                    }
                    .card__copy {
                        font-size: 14px;
                        line-height: 20px;
                        margin: 0 auto 24px auto;
                    }
                `}
            </style>
        </section>
    );
};
export default EditorialPolicySection;
