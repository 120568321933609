import {BREAKPOINT, MAX_WIDTH_PX} from 'aac-components/utils/styles';
import styled from 'styled-components';

const HomepageStyles = styled.div``;

export const TopContainerStyled = styled.div`
    display: flex;
    max-width: ${MAX_WIDTH_PX};
    margin: 0 auto;
    flex-direction: column;
    @media screen and (min-width: ${BREAKPOINT}) {
        height: 395px;
        flex-direction: row;
    }
`;

export const TopLeftContainerStyled = styled.div`
    width: 520px;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
`;

export const TopRightContainerStyled = styled.div`
    border-radius: 8px;
    overflow: hidden;
    position: relative;
    width: 570px;
    height: 395px;
`;

export const TopLeftPreTitleStyled = styled.div`
    color: var(--secondary-300);
    font-size: 12px;
    line-height: 16px;
    font-weight: 700;
`;

export const ButtonContainerStyled = styled.div`
    flex-direction: column;
    @media screen and (min-width: ${BREAKPOINT}) {
        max-width: 520px;
        width: 100%;
        display: flex;
        flex-direction: row;
        gap: 20px;
    }
`;

export const ButtonStyled = styled.div`
    max-width: 100%;
    margin-bottom: 16px;

    @media screen and (min-width: ${BREAKPOINT}) {
        max-width: 100%;
    }
`;

export const TextStyled = styled.div`
    font-size: 16px;
    line-height: 24px;
    margin-bottom: 24px;
`;

export const H2Styled = styled.h2`
    margin: 10px 0 24px;
`;

export const MapSectionStyled = styled.section`
    padding: 64px 15px;
    text-align: center;
    margin-bottom: 64px;

    @media screen and (min-width: ${BREAKPOINT}) {
        padding: 80px 0;
        text-align: left;
        margin-bottom: 120px;
    }
`;

export default HomepageStyles;
