import HiddenOnDesktop from 'aac-components/components/HiddenOnDesktop';
import HiddenOnMobile from 'aac-components/components/HiddenOnMobile';
import Image from 'next/image';
import Link from 'next/link';
import IconArrowShort from 'aac-components/components/Icons/Arrow/IconArrowShort';
import {BREAKPOINT} from '../../lib/styles';
import {MAX_WIDTH_PX} from 'aac-components/utils/styles';

const AccreditationsSection = () => {
    return (
        <div className="accreditations">
            <HiddenOnDesktop>
                <div className="accreditations__title">Industry Trusted & Accredited</div>
            </HiddenOnDesktop>
            <section className="accreditations__container">
                <HiddenOnMobile>
                    <div className="accreditations__title">
                        Industry Trusted & Accredited
                    </div>
                </HiddenOnMobile>
                <div className="accred__logo">
                    <Image
                        src="/static/joint-commission.png"
                        alt="Joint Commission Logo"
                        width={0}
                        height={0}
                        sizes="100px"
                        style={{width: '100%', height: '100%'}}
                    />
                </div>
                <div className="accred__logo">
                    <Image
                        src="/static/Logo_SAMSHA.png"
                        alt="SAMSHA Logo"
                        width={0}
                        height={0}
                        sizes="100px"
                        style={{width: '100%', height: '100%'}}
                    />
                </div>
                <div className="accred__logo">
                    <Image
                        src="/static/carf-icon.png"
                        alt="Carf Logo"
                        width={0}
                        height={0}
                        sizes="100px"
                        style={{width: '100%', height: '100%'}}
                    />
                </div>
                <HiddenOnMobile>
                    <div>
                        <Link
                            href="/accreditations"
                            style={{textDecoration: 'none'}}
                            legacyBehavior>
                            <a className="view-all-link">
                                View All Accreditations{' '}
                                <IconArrowShort fill="var(--interactive)" rotate="-90" />
                            </a>
                        </Link>
                    </div>
                </HiddenOnMobile>
            </section>
            <HiddenOnDesktop>
                <div>
                    <Link
                        href="/accreditations"
                        style={{textDecoration: 'none'}}
                        legacyBehavior>
                        <a className="view-all-link">
                            View All Accreditations{' '}
                            <IconArrowShort fill="var(--interactive)" rotate="-90" />
                        </a>
                    </Link>
                </div>
            </HiddenOnDesktop>
            <style jsx>
                {`
                    .accreditations {
                        position: relative;
                        border-top: 4px solid var(--secondary-100);
                        border-bottom: 4px solid var(--secondary-100);
                        margin: 0 10px;
                        padding: 20px 15px;
                        max-width: ${MAX_WIDTH_PX};
                    }
                    @media screen and (min-width: ${BREAKPOINT}) {
                        .accreditations {
                            padding: 20px 40px;
                            margin: 0 auto;
                        }
                    }
                    .accreditations__container {
                        display: grid;
                        grid-template-columns: repeat(3, auto);
                        align-items: center;
                        justify-content: center;
                        grid-gap: 16px;
                    }
                    @media screen and (min-width: ${BREAKPOINT}) {
                        .accreditations__container {
                            display: flex;
                            align-items: center;
                            justify-content: space-between;
                        }
                    }

                    .accreditations__container > div {
                        flex: 1;
                        max-width: 80px;
                    }

                    .accreditations__container > div:last-child {
                        min-width: fit-content;
                    }
                    .accreditations__title {
                        font-size: 22px;
                        font-family: var(--font-title);
                        font-weight: bold;
                        min-width: 300px;
                        text-align: center;
                        margin-bottom: 40px;
                    }
                    @media screen and (min-width: ${BREAKPOINT}) {
                        .accreditations__title {
                            text-align: left;
                            margin-bottom: 0;
                        }
                    }
                    .view-all-link {
                        display: flex;
                        font-weight: bold;
                        justify-content: center;
                        align-items: center;
                        grid-gap: 8px;
                        color: var(--interactive);
                        text-decoration: none;
                        margin-top: 24px;
                    }
                    @media screen and (min-width: ${BREAKPOINT}) {
                        .view-all-link {
                            color: var(--interactive);
                            margin-top: 0;
                        }
                    }
                `}
            </style>
        </div>
    );
};
export default AccreditationsSection;
