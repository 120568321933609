import Button from 'aac-components/components/Button';
import {BREAKPOINT, MAX_WIDTH_PX} from 'aac-components/utils/styles';
import React, {useContext} from 'react';
import Slider from 'aac-components/components/Slider';
import Image from 'next/image';
import Link from 'next/link';
import AppContext from '../AppContext';

const commonsProviderFolder =
    'https://admin.americanaddictioncenters.org/aac-common-bucket/providers/';
const aacInsurancePageFolder = 'https://americanaddictioncenters.org/insurance-coverage/';

const InsuranceSection = () => {
    const {isMobile} = useContext(AppContext);
    return (
        <section className="insurance-section">
            <div className="insurance-section__content">
                <div className="insurance-section__text-container">
                    <p className="insurance-section__pre-title">INSURANCE COVERAGE</p>
                    <h2 className="insurance-section__title">
                        Are You Covered For Treatment?
                    </h2>
                    <div className="insurance-section__insurance-check">
                        <span>
                            Check to see if your insurance is in-network at an American
                            Addiction Centers location.
                        </span>
                        {!isMobile && (
                            <Button
                                style={{fontWeight: 'normal'}}
                                onClick={(e) =>
                                    window && window.AAC && window.AAC.openSlideInSsvob(e)
                                }>
                                Check Coverage Now
                            </Button>
                        )}
                    </div>
                </div>
            </div>
            <div className="slider__container">
                <Slider>
                    {providerCards.map((card, index) => (
                        <Link
                            href={card.href}
                            title={card.title}
                            className="gallery__link"
                            key={index}>
                            <div className="gallery__img" key={index}>
                                {card?.image ? (
                                    <Image
                                        src={card.image}
                                        alt={card.title}
                                        width={148}
                                        height={56}
                                        style={{
                                            objectFit: 'contain',
                                            width: 'auto',
                                            height: 'auto',
                                            maxWith: 148,
                                            maxHeight: 56,
                                        }}
                                    />
                                ) : (
                                    <span
                                        dangerouslySetInnerHTML={{
                                            __html: card?.title,
                                        }}
                                        key={index}
                                    />
                                )}
                            </div>
                        </Link>
                    ))}
                </Slider>
            </div>{' '}
            {isMobile && (
                <Button
                    style={{fontWeight: 'normal'}}
                    onClick={(e) =>
                        window && window.AAC && window.AAC.openSlideInSsvob(e)
                    }>
                    Check Coverage Now
                </Button>
            )}
            <style jsx>
                {`
                    .insurance-section__text-container {
                        display: flex;
                        flex-direction: column;
                    }
                    .insurance-section__insurance-check {
                        display: flex;
                        justify-content: space-between;
                        align-items: flex-end;
                        width: 100%;
                    }
                    .insurance-section__insurance-check span {
                        flex: 1;
                        white-space: normal;
                        overflow: visible;
                        text-overflow: unset;
                    }
                    .insurance-section__insurance-check button {
                        margin-left: auto;
                        flex-shrink: 0;
                    }
                    .insurance-section__content {
                        margin-bottom: 24px;
                    }
                    .insurance-section {
                        margin: 0 auto;
                        max-width: ${MAX_WIDTH_PX};
                        padding: 44px 15px;
                        width: 100%;
                    }
                    @media screen and (min-width: ${BREAKPOINT}) {
                        .insurance-section {
                            padding: 40px 0 0;
                        }
                    }
                    .insurance-section__pre-title {
                        color: var(--secondary-300);
                        font-size: 12px;
                        line-height: 16px;
                        font-weight: 700;
                    }
                    .insurance-section__title {
                        margin: 14px 0;
                    }
                    p {
                        margin: 0;
                    }
                    .gallery__link {
                        display: inline-block;
                        border-radius: 8px;
                        overflow: hidden;
                    }
                    .gallery__img {
                        position: relative;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        min-width: 200px;
                        height: 100%;
                        overflow: hidden;
                        cursor: pointer;
                        justify-content: center;
                        border-radius: 8px;
                        border: 2px solid #ce4a18;
                        padding: 22px 16px;
                    }
                    .slider__container {
                        margin: 0 auto 40px auto;
                    }
                    :global(.slider__cards) {
                        gap: 15px;
                    }
                `}
            </style>
        </section>
    );
};
export default InsuranceSection;

const providerCards = [
    {
        title: 'Aetna',
        image: `${commonsProviderFolder}aetna.png`,
        href: `${aacInsurancePageFolder}aetna`,
    },
    {
        title: 'Anthem',
        image: `${commonsProviderFolder}anthem.png`,
        href: `${aacInsurancePageFolder}anthem`,
    },
    {
        title: 'Cigna',
        image: `${commonsProviderFolder}cigna.png`,
        href: `${aacInsurancePageFolder}cigna-insurance`,
    },
    {
        title: 'United Healthcare',
        image: `${commonsProviderFolder}united-healthcare.png`,
        href: `${aacInsurancePageFolder}unitedhealth-group`,
    },
    {
        title: 'Ambetter',
        image: `${commonsProviderFolder}ambetter.png`,
        href: `${aacInsurancePageFolder}ambetter`,
    },
    {
        title: 'Blue Cross Blue Shield',
        image: `${commonsProviderFolder}blue-cross-blue-shield.png`,
        href: `${aacInsurancePageFolder}blue-cross-blue-shield-association`,
    },
    {
        title: 'Tufts',
        image: `${commonsProviderFolder}tufts.png`,
        href: `${aacInsurancePageFolder}tufts-health-plan`,
    },
    {
        title: 'Magellan Healthcare',
        image: `${commonsProviderFolder}magellan-health.png`,
        href: `${aacInsurancePageFolder}magellan-insurance`,
    },
    {
        title: 'VA Insurance',
        image: `${commonsProviderFolder}va-2.png`,
        href: `${aacInsurancePageFolder}va-benefits`,
    },
    {
        title: 'Humana',
        image: `${commonsProviderFolder}humana.png`,
        href: `${aacInsurancePageFolder}humana`,
    },
    {
        title: 'Carelon Behavioral Health Options',
        image: `${commonsProviderFolder}carelon.svg`,
        href: `${aacInsurancePageFolder}carelon-behavioral-health`,
    },
    {
        title: 'Kaiser Permanente',
        image: `${commonsProviderFolder}kaiser.png`,
        href: `${aacInsurancePageFolder}kaiser-permanente`,
    },
    {
        title: 'Tricare',
        image: `${commonsProviderFolder}tricare.png`,
        href: `${aacInsurancePageFolder}tricare`,
    },
    {
        title: 'Amerigroup',
        image: `${commonsProviderFolder}amerigroup.png`,
        href: `${aacInsurancePageFolder}amerigroup`,
    },
    {
        title: 'AvMed',
        image: `${commonsProviderFolder}avmed.png`,
        href: `${aacInsurancePageFolder}avmed-insurance`,
    },
    {
        title: 'First Health',
        image: `${commonsProviderFolder}first-health-network.png`,
        href: `${aacInsurancePageFolder}first-health`,
    },
    {
        title: 'Emblem Health',
        image: `${commonsProviderFolder}emblem-health.png`,
        href: `${aacInsurancePageFolder}emblemhealth-insurance`,
    },
    {
        title: 'Meritain Insurance',
        image: `${commonsProviderFolder}meritain-health.png`,
        href: `${aacInsurancePageFolder}meritain-insurance`,
    },
    {
        title: 'Empire Blue Cross Blue Shield',
        image: `${commonsProviderFolder}empire-bcbs.png`,
        href: `${aacInsurancePageFolder}empire-blue-cross-blue-shield`,
    },
    {
        title: 'Geisinger',
        image: `${commonsProviderFolder}gesinger.png`,
        href: `${aacInsurancePageFolder}geisinger-insurance`,
    },
    {
        title: 'Harvard Pilgrim',
        image: `${commonsProviderFolder}harvard-pilgrim-health-care.png`,
        href: `${aacInsurancePageFolder}harvard-pilgrim-insurance`,
    },
    {
        title: 'Health Plan of Nevada',
        image: `${commonsProviderFolder}health-plan-nevada-uhc.png	`,
        href: `${aacInsurancePageFolder}health-plan-of-nevada`,
    },
    {
        title: 'MagnaCare',
        image: `${commonsProviderFolder}magnacare.png`,
        href: `${aacInsurancePageFolder}magnacare-insurance`,
    },
    {
        title: 'Managed Health Network (MHN)',
        image: `${commonsProviderFolder}mhn.png`,
        href: `${aacInsurancePageFolder}managed-health-network-mhn`,
    },
    {
        title: 'Molina Healthcare',
        image: `${commonsProviderFolder}molina.png`,
        href: `${aacInsurancePageFolder}molina-healthcare`,
    },
    {
        title: 'New Directions',
        image: `${commonsProviderFolder}new-directions.png`,
        href: `${aacInsurancePageFolder}new-directions`,
    },
    {
        title: 'Optum',
        image: `${commonsProviderFolder}optum.png`,
        href: `${aacInsurancePageFolder}optum`,
    },
    {
        title: 'Oxford Health Plans',
        image: `${commonsProviderFolder}oxford-health-plans.png`,
        href: `${aacInsurancePageFolder}oxford-health-insurance`,
    },
    {
        title: 'Premera BCBS',
        image: `${commonsProviderFolder}premera-bcbs.png`,
        href: `${aacInsurancePageFolder}blue-cross-blue-shield-association/premera`,
    },
    {
        title: 'Providence',
        image: `${commonsProviderFolder}providence.png`,
        href: `${aacInsurancePageFolder}providence-health-plan`,
    },
    {
        title: 'Rocky Mountain HMO',
        image: `${commonsProviderFolder}rocky-mt-health.png`,
        href: `${aacInsurancePageFolder}rocky-mountain-hmo`,
    },
    {
        title: 'Sierra',
        image: `${commonsProviderFolder}sierra.png`,
        href: `${aacInsurancePageFolder}sierra-health-and-life-insurance`,
    },
    {
        title: 'State Farm',
        image: `${commonsProviderFolder}state-farm.png`,
        href: `${aacInsurancePageFolder}state-farm`,
    },
    {
        title: 'Veterans Choice Program',
        image: `${commonsProviderFolder}va.jpg`,
        href: `${aacInsurancePageFolder}veterans-choice-program`,
    },
    {
        title: 'And many more...',
        image: '',
        href: 'https://americanaddictioncenters.org/insurance-providers',
    },
];
